require("../../../vendor/assets/libs/bootstrap-datepicker/bootstrap-datepicker")

// Auto update layout
if (window.layoutHelpers) {
  window.layoutHelpers.setAutoUpdate(true)
}

$(function () {
  // Initialize sidenav
  $('#layout-sidenav').each(function () {
    new SideNav(this, {
      orientation: 'vertical'
    })
  });

  // Initialize sidenav togglers
  $('body').on('click', '.layout-sidenav-toggle', function (e) {
    e.preventDefault()
    window.layoutHelpers.toggleCollapsed()
  })

  // Swap dropdown menus in RTL mode
  if ($('html').attr('dir') === 'rtl') {
    $('#layout-navbar .dropdown-menu').toggleClass('dropdown-menu-right')
  }

  $('#search-bar').on('click', function() {
    $(this).closest('label').addClass('active')
  })
  
  $('#search-field').on('blur', function() {
    let _t = $(this)

    if ( _t.val() == '' ) {
      $(this).closest('label').removeClass('active')
    }
  })

});
