import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ 'kind', 'amount', 'installmentAmount', 'balance', 'day', 'nextInstallment' ]
  static values = { refund: Boolean }

  setInstallment() {
    this.amount = this.installmentAmountTarget.dataset.amount
    this.kind = 'installment'
    $(this.kindTarget).trigger('change')
    this.checkAmount()
  }

  setDay() {
    this.day = this.nextInstallmentTarget.dataset.day
  }

  setMax() {
    this.amount = this.balance
  }

  checkAmount() {
    if ( !this.refundValue && this.amount > this.balance ) {
      this.amount = this.mask(this.balance)
    }
  }

  get balance() {
    return this.unmask(this.balanceTarget.dataset.amount)
  }

  get amount() {
    return this.unmask(this.amountTarget.value)
  }

  set amount(value) {
    this.amountTarget.value = this.mask(value)
  }
  
  set kind(value) {
    this.kindTarget.value = value
  }
  
  set day(value) {
    this.dayTarget.value = value
  }
}
