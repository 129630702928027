import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['body']
  static values = { url: String }

  urlValueChanged() {
    let _self = this

    if ( this.url == '' ) {
      return;
    }

    fetch(this.urlValue)
      .then(response => response.text())
      .then(function(html) {
        _self.body = html
        maskThem()
      })
  }

  get url() {
    return this.urlValue
  }

  set body(html) {
    this.bodyTarget.innerHTML = html;
  }
}