import ApplicationController from "./application_controller";
import * as moment from 'moment/moment.js'

export default class extends ApplicationController {
  static targets = ["discount", "total", "balance", "installmentsLeft", "installmentAmount"]
  static values = { balance: String, discount: String, total: String }

  calculate() {
    let diff = this.originalDiscount - this.discount
    this.total = this.originalTotal + diff
    this.balance = this.originalBalance + diff
    let installmentAmount = this.balance / this.installmentsLeft

    this.installmentAmount = parseFloat(installmentAmount < 0 ? 0 : installmentAmount)
  }

  resetDiscount() {
    this.discount = this.originalDiscount
    this.calculate()
  }

  get originalDiscount() {
    return this.unmask(this.discountValue)
  }

  get originalTotal() {
    return this.unmask(this.totalValue)
  }

  get originalBalance() {
    return this.unmask(this.balanceValue)
  }

  get discount() {
    return this.unmask(this.discountTarget.value)
  }

  set discount(value) {
    this.discountTarget.value = this.mask(value)
  }

  get total() {
    return this.unmask(this.totalTarget.value)
  }

  set total(value) {
    this.totalTarget.value = this.mask(value)
  }

  get balance() {
    return this.unmask(this.balanceTarget.value)
  }

  set balance(value) {
    this.balanceTarget.value = this.mask(value)
  }

  get installmentsLeft() {
    return this.unmask(this.installmentsLeftTarget.innerHTML)
  }

  set installmentAmount(value) {
    this.installmentAmountTarget.value = this.mask(value)
  }
}
