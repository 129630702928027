import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['textarea', 'counter']

  connect() {
    this.count()
  }

  count() {
    this.counter = this.textarea.length;
  }

  get textarea() {
    return this.textareaTarget.value;
  }

  set counter(value) {
    this.counterTarget.innerHTML = value
  }
}