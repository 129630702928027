require("../../../vendor/assets/libs/datatables/datatables")
require("./jquery.dataTables.yadcf")

$(function() {
  $.extend(true, $.fn.dataTable.defaults, {
    dom: 'pTlgtip',
    processing: true,
    serverSide: true,
    stateDuration: -1,
    bSortCellsTop: true,
    deferRender: true,
    responsive: true,
    bRetrieve: true,
    autoWidth: false,
    pageLength: 25,
    lengthMenu: [ [25, 50, 100, 250], [25, 50, 100, 250] ],
    stateSave: true,
    language: {
      "sEmptyTable":     "Nessun dato presente nella tabella",
      "sInfo":           "Vista da _START_ a _END_ di _TOTAL_ elementi",
      "sInfoEmpty":      "Vista da 0 a 0 di 0 elementi",
      "sInfoFiltered":   "(filtrati da _MAX_ elementi totali)",
      "sInfoPostFix":    "",
      "sInfoThousands":  ".",
      "sLengthMenu":     "Visualizza _MENU_ elementi",
      "sLoadingRecords": "Caricamento...",
      "sProcessing":     "Elaborazione...",
      "sSearch":         "Cerca Generico:",
      "sZeroRecords":    "La ricerca non ha portato alcun risultato.",
      "oPaginate": {
        "sFirst":      "Inizio",
        "sPrevious":   "&laquo;",
        "sNext":       "&raquo;",
        "sLast":       "Fine"
      },
      "oAria": {
        "sSortAscending":  ": attiva per ordinare la colonna in ordine crescente",
        "sSortDescending": ": attiva per ordinare la colonna in ordine decrescente"
      }
    },
    createdRow: function( row, data, dataIndex ) {
      if (data.row_class !== undefined) {
        $(row).addClass(data.row_class )
      }
    }
  })

  let dataTables = []

  $('.dataTable').each(function(k, v) {
    let id = $(this).attr('id')
    let config = $(this).data('config')

    dataTables[id] = $(this).DataTable({
      ajax: {
        url: config.path
      },
      columns: config.columns
    })

    dataTables[id].on('draw.dt', function() {
      window.maskThem()
      window.select2Them()
      window.tooltipThem()
    })

    yadcf.init(dataTables[id], config.filters, { 
      filters_tr_index: 1, 
      language:  {
        select: 'Seleziona',
        select_multi: 'Seleziona',
        filter: 'Cerca',
        range: ['Dal', 'Al'],
        date: 'Data'
      }
    })
  })

})
