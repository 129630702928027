import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['region', 'province']

  checkAll() {
    this.provinceTargets.forEach((province) => {
      if ( province.disabled == false ) {
        province.checked = this.regionTarget.checked
      }
    })
  }
}
