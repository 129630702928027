import ApplicationController from "../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['chart']
  static values = {
    series: Array
  }

  chart = null
  MONTHS = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
  COLORS = {
    contacts: '3b82f6',
    subscription: 'f97316'
  }

  connect() {
    this.draw()
  }

  draw() {
    console.log('draw', this.options, this.series)
    this.chart = JSC.chart(this.chartTarget, {
      defaultCultureName: 'it-IT',
      template: this.options,
      height: this.height,
      series: this.series
    })
  }

  get series() {
    return this.seriesValue
  }

  get height() {
    return 400
  }
}
