import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['modal', 'form']
  static values = { update: String }

  success(event) {
    let response = event.detail[0]
    let check = this.updateValue

    $('select[id^=school_exam]').each(function() {
      let newOption = new Option(response.name, response.id, false, this.id == check);
      $(this).append(newOption).trigger('change');
    })

    this.closeModal()
  }

  error(event) {
    console.log(event)
    this.closeModal()
  }

  closeModal() {
    $(this.modalTarget).modal('hide')
  }
}