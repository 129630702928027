import { Controller } from "stimulus"

export default class extends Controller {
  
  unmask(value) {
    return parseFloat(Inputmask.unmask(value, { alias: 'currency', radixPoint: ',', showMaskOnHover: false }).replace(',', '.')) || 0 
  }
  mask(value) {
    return String(value).replace('.', ',')
  }
}