import { Calendar } from '@fullcalendar/core'
import timegridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import itLocale from '@fullcalendar/core/locales/it'

let calendarEl = document.getElementById('dashboard-calendar')
if ( calendarEl !== null ) {
  let calendar = new Calendar(calendarEl, {
    plugins: [ 
      bootstrapPlugin,
      timegridPlugin,
      listPlugin
    ],
    themeSystem: 'bootstrap',
    header: {
      left: 'title',
      center: 'listDay,listWeek,listMonth',
      right: 'prev,today,next'
    },
    buttonText: {
      'listDay': 'Oggi',
      'listWeek': 'Settimana',
      'listMonth': 'Mese'
    },
    defaultDate: new Date(),
    defaultView: 'listDay',
    allDaySlot: false,
    navLinks: true,
    nowIndicator: true,
    firstDay: 1,
    businessHours: {
      daysOfWeek: [ 1, 2, 3, 4, 5 ],
      startTime: '08:00',
      endTime: '21:00',
    },
    minTime: '08:00:00',
    maxTime: '21:00:00',
    eventLimit: true,
    events: calendarEl.dataset.feed,
    // contentHeight: 500,
    locale: itLocale,
    eventRender: function(info) {
      let icon = info.event.extendedProps.icon;
      let kind = info.event.extendedProps.kind;

      if ( icon ) {
        $(info.el).find(".fc-list-item-marker").html("<i class='text-white fa " + icon + "'></i>");
      }

      if ( kind ) {
        $(info.el).find(".fc-list-item-marker").addClass(`bg-${kind}`)
      }

      $(info.el).find(".fc-list-item-title a").html(info.event.title)
    }
  });

  calendar.render();
}