import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['year', 'contacts', 'subscriptions', 'sales', 'salesMonths']
  static values = {
    url: String
  }

  contactsChart = null
  subscriptionsChart = null
  salesChart = null
  salesMonthsChart = null
  columnsChartOptions = {
    height: 400,
    defaultSeries_type: 'columnSolid',
    yAxis_scale_type: 'stacked',
    defaultPoint_tooltip: '%yValue <span style="color:%color"><b>%seriesName</b></span>',
    defaultSeries: {
      pointSelection: true
    },
    legend: {
      position: 'bottom',
      defaultEntry: {
        style: {
          fontSize: '16px',
        }
      }
    },
    animation_duration: 500,
    xAxis: {
      crosshair: {
        enabled: true,
        gridLine: {
          width: 'column',
          color: '#dfdfdf',
          opacity: 0.5
        }
      }
    }
  }

  connect() {
    this.setupCharts()
    this.loadData()
  }

  setupCharts() {
    this.setupContactsChart()
    this.setupSubscriptionsChart()
    this.setupSalesChart()
    // this.setupSalesMonthChart()
  }

  setupContactsChart() {
    this.contactsChart = JSC.chart(this.contactsTarget, this.columnsChartOptions)
  }

  setupSubscriptionsChart() {
    this.subscriptionsChart = JSC.chart(this.subscriptionsTarget, {
      type: 'radar column',
      legend_visible: false,
      height: 450,
      defaultSeries: {
        color: '#ea580c'
      }
    });
  }

  setupSalesChart() {
    this.salesChart = JSC.chart(this.salesTarget, {
      type: 'pie donut',
      height: 450,
      legend: {
        position: 'right',
        defaultEntry: {
          style: {
            fontSize: '16px',
          }
        }
      },
      defaultSeries: {
        pointSelection: true
      }
    })
  }

  setupSalesMonthChart() {
    let options = this.columnsChartOptions
    options.events = {
      redraw: function(e) { console.log('redraw', e, this.options('series')) }
    }
    options.yAxis =  [
      {
        id: 'contacts',
        scale_type: 'stacked',
        pointSelection: true
      }, {
        id: 'subscriptions',
        orientation: 'opposite',
        scale_type: 'stacked',
        pointSelection: true
      }
    ]

    this.salesMonthsChart = JSC.chart(this.salesMonthsTarget, options)
  }

  loadData() {
    JSC.fetch(this.url)
      .then(response => response.json())
      .then(json => {
        this.updateCharts(json)
      })
  }

  updateCharts(data) {
    this.updateContactsChart(data.contacts)
    this.updateSubscriptionsChart(data.subscriptions)
    this.updateSalesChart(data.sales)
    // this.updateSalesMonthChart(data.sales_months)
  }

  updateContactsChart(data) {
    this.contactsChart.options({
      xAxis: {
        categories: data.categories
      },
      series: data.series
    })
  }

  updateSubscriptionsChart(data) {
    this.subscriptionsChart.options({series: data.series})
  }

  updateSalesChart(data) {
    this.salesChart.options({series: data.series})
  }

  updateSalesMonthChart(data) {
    this.salesMonthsChart.options({
      xAxis: {
        categories: data.categories
      },
    })
    this.salesMonthsChart.series().remove()
    data.series.forEach((serie)=> {
      this.salesMonthsChart.series.add(serie)
    })
  }

  get url() {
    let url = new URL(this.urlValue)
    url.searchParams.append('year', this.year)

    return url
  }

  get year() {
    return  this.yearTarget.value
  }
}
