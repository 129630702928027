// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("@nathanvda/cocoon")
// require("channels")
require("controllers")

// Imports
require("../../../vendor/assets/libs/growl/growl")
require("../../../vendor/assets/libs/minicolors/minicolors")

// Import images, use as image_pack_path('icon.png')
require.context('../images', true)

// Include main stylesheet
require("../source/main.scss")

// Include main file
require("../source/main")
require("../source/datatable")
require("../source/app")
