import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['amount', 'installments']
  static values = { amount: String, discount1: String, discount2: String, threshold: Number }

  connect() {
    this.updatePrice()
  }
  updatePrice() {
    let amount = this.amount

    if ( this.installments <= 1 ) {
      amount = this.discount2
    } else if ( this.installments <= this.threshold ) {
      amount = this.discount1
    }

    this.amountTarget.innerHTML = amount
  }

  get installments() {
    return parseInt(this.installmentsTarget.value)
  }

  get amount() {
    return this.amountValue
  }

  get discount1() {
    return this.discount1Value
  }

  get discount2() {
    return this.discount2Value
  }

  get threshold() {
    return this.thresholdValue
  }
}
