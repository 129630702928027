import ApplicationController from "./application_controller";
import * as moment from 'moment/moment.js'

export default class extends ApplicationController {
  static targets = [
    "course",
    "subscribedAt",
    "registration",
    "amount",
    "discount",
    "overcharge",
    "individualHours",
    "individualRate",
    "total",
    "amountBy",
    "amountByDay",
    "installments",
    "installmentAmount",
    "installmentBig",
    "installmentBigAmount",
    "deposit",
    "balance",
    "underage",
    "underageLabel",
    "birthdate",
    "parentCard",
    "paid", 
    "installmentsPaid",
    "installmentsBigPaid",
    "installmentsLeft"
  ]
  static values = { amount: String, discount1: String, discount2: String, maxInstallments: Number, threshold: Number }
  connect() {
    if ( this.hasRegistrationTarget ) {
      this.calculateTotal()
      this.setMaxInstallments()
      this.checkAge()
    }
  }

  courseSwitch(event) {
    let data = this.selectedCourseData

    this.registration = data.registration
    this.overcharge = data.overcharge

    this.amountValue = data.amount
    this.discount1Value = data.discount1
    this.discount2Value = data.discount2
    this.maxInstallmentsValue = data.maxInstallments

    this.calculateDiscountedAmount()
    this.setMaxInstallments()
    this.calculateTotal()
  }

  setMaxInstallments() {
    let select = this.installmentsTarget
    let options = Array.from(select.options)
    let max = this.maxInstallmentsValue

    options.forEach((option) => {
      option.disabled = option.value > max
    })

    if ( select[select.selectedIndex].value > max ) {
      select.querySelector(`option[value="${max}"]`).selected = true
      $(select).trigger('change')
    }
  }

  individual() {
    return this.individualHours * this.individualRate
  }
  
  calculateTotal() {
    this.total = this.registration + this.amount - this.discount + this.overcharge + this.individual()
    this.calculateBalance()
    this.calculateInstallmentsLeft()
  }

  calculateDiscountedAmount() {
    let amount = this.amountValue

    if ( this.installments <= 1 ) {
      amount = this.discount2Value
    } else if ( this.installments <= this.thresholdValue ) {
      amount = this.discount1Value
    }

    this.amount = amount
    this.calculateTotal()
  }

  perInstallment() {
    let installmentBigAmount = 0
    if ( this.bigInstallmentsNumber() > 0 ) { installmentBigAmount = this.amountBy / this.bigInstallmentsNumber() }

    let balance = this.balance - ( ( this.bigInstallmentsNumber() - this.installmentsBigPaid ) * installmentBigAmount )
    let installmentAmount = balance / ( this.installmentsLeft)

    this.installmentBigAmount = parseFloat(installmentBigAmount < 0 ? 0 : installmentBigAmount)
    this.installmentAmount = parseFloat(installmentAmount < 0 ? 0 : installmentAmount)
  }

  calculateInstallmentsLeft() {
    let installmentsLeft = this.installments - this.installmentsPaid - this.bigInstallmentsNumber() + this.installmentsBigPaid

    this.installmentsLeft = installmentsLeft > 0 ? installmentsLeft : 0
    this.perInstallment()
  }

  calculateBalance() {
    this.balance = this.total - this.deposit - this.paid
    this.perInstallment()
  }

  checkAge() {
    if ( this.isUnderage() ) {
      this.underageTarget.value = true
    } else {
      this.underageTarget.value = false
    }

    this.toggleParent()
  }

  isUnderage() {
    if ( this.birthdate === null ) {
      return false
    }

    return moment.duration(this.subscribedAt.diff(this.birthdate)).asYears() < 18
  }

  toggleParent() {
    this.fixSelect2()

    if ( this.underageTarget.value == 'true' ) {
      this.parentCardTarget.classList.remove('d-none')
      this.underageLabelTarget.classList.remove('d-none')
    } else {
      this.parentCardTarget.classList.add('d-none')
      this.underageLabelTarget.classList.add('d-none')
    }
  }

  fixSelect2() {
    for (let select of document.getElementsByClassName('select2-container')) {
      select.style.width = '100%'
    }
  }

  dataToMoment(s) {
    if ( s == '' || s.replace(/_/g, '').length < 10 ) return null;

    return moment(s.split('/').reverse().join('-'))
  }

  bigInstallmentsNumber() {
    if ( this.amountByDay == null || this.subscribedAt == null || this.amountBy == 0 ) return 0
    let number = this.amountByDay.diff(this.subscribedAt, 'months')

    this.installmentBig = number

    return number
  }

  get selectedCourseData() {
    let select = this.courseTarget
    return select.options[select.selectedIndex].dataset
  }

  get birthdate() {
    return this.dataToMoment(this.birthdateTarget.value)
  }

  get subscribedAt() {
    return this.dataToMoment(this.subscribedAtTarget.value)
  }

  get registration() {
    return this.unmask(this.registrationTarget.value)
  }

  set registration(v) {
    this.registrationTarget.value = v
  }

  get amount() {
    return this.unmask(this.amountTarget.value)
  }

  set amount(v) {
    this.amountTarget.value = v
  }

  get deposit() {
    return this.unmask(this.depositTarget.value)
  }

  get discount() {
    return this.unmask(this.discountTarget.value)
  }
  
  get overcharge() {
    return this.unmask(this.overchargeTarget.value)
  }

  set overcharge(v) {
    this.overchargeTarget.value = v
  }

  get individualHours() {
    return parseInt(this.individualHoursTarget.value) || 0
  }

  get individualRate() {
    return this.unmask(this.individualRateTarget.value)
  }

  get paid() {
    return this.unmask(this.paidTarget.innerHTML)
  }

  get total() {
    return this.data.get('total')
  }

  get amountBy() {
    return this.unmask(this.amountByTarget.value)
  }

  get amountByDay() {
    return this.dataToMoment(this.amountByDayTarget.value)
  }

  set total(value) {
    this.data.set('total', value)
    this.totalTarget.value = this.mask(this.total)
  }

  get installments() {
    return parseInt(this.installmentsTarget.value) || 1
  }

  get installmentAmount() {
    return this.unmask(this.installmentAmountTarget.value)
  }

  set installmentAmount(value) {
    this.installmentAmountTarget.value = this.mask(value)
  }

  set installmentBigAmount(value) {
    this.installmentBigAmountTarget.innerHTML = this.mask(value)
  }

  get deposit() {
    return this.unmask(this.depositTarget.value)
  }

  get balance() {
    return this.data.get('balance')
  }

  set balance(value) {
    this.data.set('balance', value)
    this.balanceTarget.value = this.mask(this.balance)
  }

  get installmentsPaid() {
    return this.unmask(this.installmentsPaidTarget.innerHTML)
  }

  get installmentsBigPaid() {
    return this.unmask(this.installmentsBigPaidTarget.value)
  }

  get installmentsLeft() {
    return this.unmask(this.installmentsLeftTarget.innerHTML)
  }

  set installmentsLeft(value) {
    this.installmentsLeftTarget.innerHTML = value
  }

  set installmentBig(value) {
    this.installmentBigTarget.value = value
  }

  set installmentBigAmount(value) {
    this.installmentBigAmountTarget.value = value
  }
}
