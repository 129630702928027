import Inputmask from "inputmask"
import 'select2/dist/js/select2.full.js'
import 'select2/dist/js/i18n/it.js'
import Rails from "@rails/ujs"

require("./dashboard")

$(function() {
  flash.forEach(function(message) {
    let notification = $.growl
    
    switch(message[0])
    {
      case 'alert':
        notification = $.growl['warning']
      break
    }

    notification({
      title: 'Info',
      message: message[1],
      location: 'br'
    })
  })

  // Tooltips
  tooltipThem()

  // Masks
  maskThem()

  // Select
  select2Them()

  $('.datepicker').datepicker({
    format: 'dd/mm/yyyy',
    autoclose: true,
    todayHighlight: true,
    language: 'it',
    daysOfWeekHighlighted: "0,6",
    weekStart: 1
  })

  $('.colorpicker').minicolors({
    control:  'hue',
    position: 'bottom',
  });

  $('.datepicker[data-callback="appointments"]').on('changeDate', function(e) {
    let date = e.format()
    let url = this.dataset.url + `?start=${date}&end=${date}`
    $('#appointment_day').val(date)

    Rails.ajax({
      type: "get",
      url: url,
      success: function(r) {
        let appointments = r.map(appointmentTemplate)
        let info = $("#appointments-info")

        $(".appointment-date").text(date)
        $("#appointments-list").html(appointments.join(''))
        
        if ( appointments.length == 0 ) {
          info.removeClass('d-none')
        } else {
          info.addClass('d-none')
        }
      }
    })
  })

  $('.with_appointment').on('change', function() {
    let appointment = $('#appointments-form')
    let list = $("#appointments-wrapper")
    
    if ( $(this).is(':checked') ) {
      appointment.removeClass('d-none')
      list.removeClass('d-none')
    } else {
      appointment.addClass('d-none');
      list.addClass('d-none')
    }
  })

  $('#modals-didactic, #modals-isuonline, .modal-ajax').on('show.bs.modal', function (event) {
    event.target.dataset.didacticUrlValue = $(event.relatedTarget).data('url')
  })

  $('.modal-ajax').on('show.bs.modal', function (event) {
    event.target.dataset.urlValue = $(event.relatedTarget).data('url')
  })

  $('#modal-school').on('show.bs.modal', function (event) {
    event.target.dataset.schoolUpdateValue = $(event.relatedTarget).data('update')
  })

  $('body').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    maskThem()
    select2Them()
  });

  clipboard()
  openModal()

})


function appointmentTemplate(appointment) {
  return `<div class="border-bottom p-2 ${appointment.class}">
    <div class="d-flex justify-content-between">
      <div class="align-items-center">
        <div class="text-muted">${appointment.start_at} - ${appointment.end_at}</div>
        <div>
          <i class="fa ${appointment.kind_icon} mr-1"></i>
          <span>${appointment.description}</span>
        </div>
      </div>
      <div class="align-items-center d-flex ml-4">
        <span class="badge badge-outline-dark">
          <i class="fa fa-user mr-1"></i>
          ${appointment.user_name}
        </span>
      </div>
    </div>
  </div>`
}

function clipboard() {
  $('body').tooltip({
    selector: '.in-clipboard',
    trigger: 'click',
    placement: 'bottom',
    title: 'Testo Copiato!'
  });

  $(document).on('click', '.in-clipboard', function(){
    window.getSelection().selectAllChildren(this)
    document.execCommand('copy')
    var $this = $(this)
    setTimeout(function(){
      $this.tooltip('hide')
    }, 1500)
  })
}

function openModal() {
  if ( auto_open_modal != '' ) {
    let modal = $('#' + auto_open_modal)
  
    if ( modal.length > 0 ) {
      modal.modal('toggle')
    }
  }
}

window.maskThem = function() {
  Inputmask({ alias: 'datetime', inputFormat: 'dd/mm/yyyy', placeholder: '_' }).mask(document.querySelectorAll('.mask-date'))
  Inputmask({ alias: 'email' }).mask(document.querySelectorAll('.mask-email'))
  Inputmask({ alias: 'currency', radixPoint: ',' }).mask(document.querySelectorAll('.mask-currency'))
}

window.select2Them = function() {
  $('.select2-select').select2({
    placeholder: '---',
    language: "it",
    width: '100%'
  }).on('select2:select', function() {
    let event = new Event('change', { bubbles: true })
    this.dispatchEvent(event)
  })

  $('.select2-ajax').select2({
    placeholder: '---',
    language: "it",
    ajax: {
      dataType: 'json',
      delay: 250,
      url: function() {
        return $(this).data('search-url')
      }
    }
  })
}

window.tooltipThem = function() {
  $('[data-toggle="tooltip"]').tooltip()
}
