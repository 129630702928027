import ApplicationController from "../application_controller";

export default class extends ApplicationController {

  get options() {
    return {
      type: 'horizontal column solid',
      height: 800,
      legend: {
        position: 'top',
        template: '{Math.abs(%value)} %icon %name'
      },
      animation_duration: 1000,
      xAxis: {
        crosshair_enabled: true,
        defaultTick: {
          // gridLine_visible: false
        }
      },
      yAxis: {
        defaultTick_label_text: '{Math.abs(%value)}',
        scale: {
          type: 'stacked'
        },
        scale_range: [-40]
      },
      defaultSeries: {
        pointSelection: true
      },
      defaultTooltip: {
        combined: true
      },
      defaultPoint: {
        tooltip: '{Math.abs(%yValue)} <span style="color:%color"><b>%seriesName</b></span>',
        label: {
          text: '{Math.abs(%yValue)}',
          placement: 'outside',
          autoHide: false,
          color: '#fff'
        }
      }
    }
  }

  get height() {
    return 800
  }
}
