import ApplicationController from "./application_controller";
import Rails from "@rails/ujs"

export default class extends ApplicationController {
  static targets = [ 'form', 'date' ]
  static values = {
    today: String
  }

  submit() {
    Rails.fire(this.formTarget, 'submit')
  }

  success(event) {
    // const [data, status, xhr] = event.detail;
    this.ok()
  }

  error(event) {
    this.ko()
  }

  setToday() {
    this.dateTarget.value = this.todayValue
    this.submit()
  }

  ok() {
    $.growl({
      title: 'Successo!',
      message: 'Salvataggio completato.',
      location: 'br'
    })
  }

  ko() {
    $.growl['warning']({
      title: 'Attenzione!',
      message: 'Si è verificato un errore, riprovare.',
      location: 'br'
    })
  }
}
