import ApplicationController from "../application_controller";

export default class extends ApplicationController {

  get options() {
    return {
      height: 400,
      type: 'area spline',
      xAxis: {
        crosshair_enabled: true,
        scale: {
          type: 'time',
          interval: { unit: 'month' }
        }
      },
      legend: {
        position: 'inside topRight'
      },
      defaultSeries: {
        shape: {
          fill: ['lightenMore', '#f1f1f1', 90]
        },
        defaultPoint: {
          tooltip: '%yValue <span style="color:%color"><b>%seriesName</b></span>',
          marker: {
            fill: 'white',
            type: 'circle',
            outline: {width: 2}
          }
        }
      }
    }
  }
}
